@font-face {
  font-family: 'Integral';
  src: local('Integral'), url(/public/fonts/Integral/OTF/IntegralCF-Heavy.otf) format('opentype');
}

:root {
  --vibrant-color: #1c8c1c;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  font: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}

::selection {
  background-color: var(--vibrant-color);
  color: white;
}

h1 {
  font-size: 64px;
}

h3 {
  font-weight: bold;
  font-size: 32px;
}

.f {
  display: flex;
}
.fdc {
  flex-direction: column;
}
.fac {
  align-items: center;
}
.fjc {
  justify-content: center;
}
.fw {
  flex-wrap: wrap;
}

.cmt64 > * + * {
  margin-top: 64px;
}

.cml32 > * + * {
  margin-left: 32px;
}

.mb128 {
  margin-bottom: 128px;
}

.mb256 {
  margin-bottom: 256px;
}

.center {
  text-align: center;
}

#root {
  background-color: white;
  color: black;
  font-size: 24px;
  min-height: 100vh;
  padding-bottom: 64px;
  font-family: 'Bad Script', cursive;
}

#hero-container {
  position: relative;
  font-family: Integral, monospace;

  > .title {
    position: absolute;
    top: 16px;
    left: 16px;
    max-width: calc(512px + 64px);
    font-size: 32px;

    > *:not(h1) {
      white-space: nowrap;
    }
  }

  h1 {
    line-height: 48px;
    margin-bottom: 16px;
  }
}

#main-vid {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

#flags > * {
  cursor: pointer;
}

.square-vid {
  width: 256px;
  height: 256px;
}

#content,
#menu {
  width: 1024px;
  max-width: 100%;
  padding: 0 64px;
}

#menu {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 64px;
  border-bottom: 1px solid #eaeaea;
  padding-top: 16px;
  padding-bottom: 16px;
}

#links {
  display: grid;
  grid-template-columns: repeat(4, min-content);
  grid-gap: 32px;
  align-items: center;

  > * {
    white-space: nowrap;
    font-size: 16px;
    padding: 2px 8px;
    cursor: pointer;
    user-select: none;
  }
}

#flags {
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-gap: 16px;
  align-items: center;
}

#content {
  padding-top: 64px;
}

.toggle {
  display: flex;
  justify-content: center;
  align-items: center;

  > * {
    padding: 0 16px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: all 150ms ease-in;

    &.active {
      color: var(--vibrant-color);
    }

    &:hover {
      border-bottom-color: var(--vibrant-color);
    }
  }

  > * + * {
    margin-left: 16px;
  }
}

.cml16 > * + * {
  margin-left: 16px;
}

.cml32 > * + * {
  margin-left: 32px;
}

.recap.odd > * + * {
  margin-left: 32px;
}

.recap.even {
  flex-direction: row-reverse;

  > * + * {
    margin-right: 32px;
  }
}

.banner {
  width: 100%;
  margin: 256px 0;
  &.first {
    margin-top: 0;
  }
}

.t {
  white-space: pre-line;
}

#cute {
  width: 256px;
  margin: 0 auto;
  margin-top: 256px;

  background-color: white;
  border: 1px solid black;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 700px) {
  #menu {
    grid-template-columns: initial;
    grid-gap: 16px;
    justify-content: center;
  }

  #content,
  #menu {
    padding-right: 16px;
    padding-left: 16px;
  }

  #hero-container .title {
    transform: scale(0.55);
    transform-origin: 0 0;
  }

  #flags {
    justify-content: center;
  }

  .banner {
    margin: 128px 0;
    &.first {
      margin-top: 0;
    }
  }

  .recap {
    flex-direction: column !important;
    align-items: center;

    video {
      height: initial;
      width: 100%;
    }

    > * {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    > span {
      margin-top: 16px;
    }
  }
}
